import React, { memo, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Alert } from 'components/ui/Alert';

import Styles from './styles.scss';

const test = ['', ''];

export const usePermission = () => {
  const roles = useSelector(({ auth }) => auth.getIn(['profile', 'roles'], []), shallowEqual);
  const combinePermissions = useMemo(() => {
    const permissionsList = roles
      .reduce((results, item) => {
        return [
          ...results,
          ...item.permissions
        ];
      }, [])
      .map(item => item.name.toUpperCase()).filter(item => !test.includes(item));

    return [...new Set(permissionsList)];
  }, [roles]);

  return combinePermissions;
};

export const usePermissionRender = (params = {}) => {
  const combinePermissions = usePermission();
  const { required = [], oneOf = [] } = params;

  // methods
  const checkRequired = (permissionsList, requiredPermissions) => (requiredPermissions.length ? requiredPermissions.every(premissionName => permissionsList.includes(premissionName)) : true);
  const checkOneOf = (permissionsList, oneOfPermissions) => (oneOfPermissions.length ? oneOfPermissions.some(premissionName => permissionsList.includes(premissionName)) : true);

  const isRender = [
    checkRequired(combinePermissions, required),
    checkOneOf(combinePermissions, oneOf)
  ].every(results => results);

  return isRender;
};

export const withPermission = Component => {
  const C = props => {
    const permissons = usePermission();

    return <Component {...props} permissions={permissons} />;
  };

  C.displayName = 'withPermission';

  return memo(C);
};

export const PermissionProvider = memo(({ children, isAlert, ...props }) => {
  const isRender = usePermissionRender(props);
  const { t } = useTranslation('GLOBAL');

  if (isRender) {
    return children;
  }

  return isAlert && (
    <div className="wrapper">
      <Alert className={Styles.alert} content={t('NOT_ACCESS')} />
    </div>
  );
});

PermissionProvider.displayName = 'PermissionProvider';

PermissionProvider.propTypes = {
  oneOf: propTypes.arrayOf(propTypes.string),
  required: propTypes.arrayOf(propTypes.string),
};
